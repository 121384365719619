/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDZa2sNn5urio-ZJkNZkbvGsDK_-UG2MhU",
  "appId": "1:495380250100:web:9890107bf4bc7ee1c38da1",
  "authDomain": "schooldog-i-tattnall-ga.firebaseapp.com",
  "measurementId": "G-9K65HE99NJ",
  "messagingSenderId": "495380250100",
  "project": "schooldog-i-tattnall-ga",
  "projectId": "schooldog-i-tattnall-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-tattnall-ga.appspot.com"
}
